<template>
  <div>
    <!-- Swiper -->
    <swiper
      class="swiper-responsive-breakpoints px-2 py-2"
      :options="swiperOptions"
    >
      <swiper-slide v-for="(product, index) in relatedProducts" :key="index">
        <b-link>
          <div class="img-container mx-auto py-75">
            <b-img :src="product.image" fluid />
          </div>
        </b-link>
      </swiper-slide>

      <!-- Add Arrows -->
      <div slot="button-next" class="swiper-button-next" />
      <div slot="button-prev" class="swiper-button-prev" />
    </swiper>
  </div>
</template>

<script>
import { BCardBody, BCardText, BImg, BLink } from "bootstrap-vue";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";

export default {
  components: {
    BCardBody,
    BCardText,
    BImg,
    BLink,

    // 3rd Party
    Swiper,
    SwiperSlide,
  },
  setup() {
    const swiperOptions = {
      slidesPerView: 5,
      spaceBetween: 25,
      loop: true,
      autoplay: {
        delay: 2500,
        disableOnInteraction: false,
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      breakpoints: {
        1600: {
          slidesPerView: 4,
          spaceBetween: 25,
        },
        1300: {
          slidesPerView: 3,
          spaceBetween: 25,
        },
        768: {
          slidesPerView: 2,
          spaceBetween: 25,
        },
        320: {
          slidesPerView: 1,
          spaceBetween: 25,
        },
      },
    };

    /* eslint-disable global-require */
    const relatedProducts = [
      {
        image: require("@/assets/images/home/locale/locale1.jpg"),
      },
      {
        image: require("@/assets/images/home/locale/locale2.jpg"),
      },
      {
        image: require("@/assets/images/home/locale/locale3.jpg"),
      },
      {
        image: require("@/assets/images/home/locale/locale4.jpg"),
      },
      {
        image: require("@/assets/images/home/locale/locale5.jpg"),
      },
    ];
    /* eslint-disable global-require */

    return {
      swiperOptions,
      relatedProducts,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/swiper.scss";
@import "~swiper/css/swiper.css";
</style>
