<template>
  <div class="footer">
    <div class="text">
      <b-row>
        <b-col sm="6">
          <h1 style="color: white">Contattaci</h1>
          <a href="tel:0112076737" class="text-muted">011 207 6737</a>
          <br />
          <a
            href="mailto:info@ginopanino.com"
            class="text-muted"
            style="color: white"
            >info@ginopanino.com</a
          >
        </b-col>
        <b-col sm="6">
          <h1 style="color: white">Seguici sui Social</h1>
          <a
            href="https://www.instagram.com/ginopanino_official/"
            target="_ blank"
            ><img
              src="@/assets/images/footer/instagram.png"
              loading="lazy"
              class="icona"
          /></a>
          <a href="https://www.facebook.com/gino.panino.56" target="_ blank"
            ><img
              src="@/assets/images/footer/facebook.png"
              loading="lazy"
              class="icona"
          /></a>
          <a
            href="https://l.facebook.com/l.php?u=https%3A%2F%2Fapi.whatsapp.com%2Fsend%3Fphone%3D393475267552%26app%3Dfacebook%26entry_point%3Dpage_cta%26fbclid%3DIwAR1lDquJMwEjy8LxMxfpNJAXnXeDCBaibLX8auhAJCbGsMnlyXWGdlWrlPg&h=AT2Sv_KA-pj5mDwzFgCYmveh3fsh_8OgjehHqI6FCdD96XCgh6WFlEWIcw026IIljje7J6e27zPWNkbutoT3YRgBQR5c8xizSXDfpjDi07SVOA0Wz45_0Vt-STmOA1BUSKb2QSX7JPWml1JQ_4oquWsc"
            target="_ blank"
            ><img
              src="@/assets/images/footer/whatsapp.png"
              loading="lazy"
              class="icona"
          /></a>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.footer {
  width: 100%;
  height: 270px;
  background-image: url("../assets/images/footer/background-footer-blue.png");
  background-size: 100% 100%;
  text-align: center;
  overflow: hidden;
}

.text {
  padding-top: 100px;
  color: white;
}
.icona {
  width: 30px;
  margin-right: 20px;
}
</style>