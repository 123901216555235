<template>
  <div style="margin: 50px 20px">
    <!-- Swiper -->
    <swiper
      class="swiper-responsive-breakpoints px-2 py-2"
      :options="swiperOptions"
    >
      <swiper-slide v-for="(product, index) in relatedProducts" :key="index">
        <b-link>
          <b-card>
            <div class="img-container">
              <b-img :src="product.image" fluid />
            </div>
            <div class="item-heading">
              <h5 class="text-truncate mb-0">
                {{ product.name }}
              </h5>
            </div>
          </b-card>
        </b-link>
      </swiper-slide>

      <!-- Add Arrows -->
      <div slot="button-next" class="swiper-button-next center" />
      <div slot="button-prev" class="swiper-button-prev center" />
    </swiper>
  </div>
</template>

<script>
import { BCardBody, BCardText, BImg, BLink } from "bootstrap-vue";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";

export default {
  components: {
    BCardBody,
    BCardText,
    BImg,
    BLink,

    // 3rd Party
    Swiper,
    SwiperSlide,
  },
  setup() {
    const swiperOptions = {
      slidesPerView: 5,
      spaceBetween: 40,
      loop: true,
      autoplay: {
        delay: 2500,
        disableOnInteraction: false,
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      breakpoints: {
        1600: {
          slidesPerView: 4,
          spaceBetween: 55,
        },
        1300: {
          slidesPerView: 3,
          spaceBetween: 55,
        },
        768: {
          slidesPerView: 2,
          spaceBetween: 55,
        },
        320: {
          slidesPerView: 1,
          spaceBetween: 55,
        },
      },
    };

    /* eslint-disable global-require */
    const relatedProducts = [
      {
        name: "Il Liscio",
        image: require("@/assets/images/menu/panini/Liscio.jpg"),
        ingredienti: "Salsiccia d’Muncalè o Würstel e una salsa a scelta",
        prezzo: 4.5,
      },
      {
        name: "Il Pigiamino",
        image: require("@/assets/images/menu/panini/Pigiamino.jpg"),
        ingredienti: "Salsiccia d’Muncalè, Formaggio e cipolla caramellata",
        prezzo: 5.5,
      },
      {
        name: "Lo Spinagino",
        image: require("@/assets/images/menu/panini/Spinagino.jpg"),
        ingredienti:
          "Salsiccia d’Muncalè, 4 fette di Scamorza affumicata calabra, foglie di spinaci e maionese",
        prezzo: 6.9,
      },
      {
        name: "La Bestia",
        image: require("@/assets/images/menu/panini/Bestia.jpg"),
        ingredienti:
          "Salsiccia d’Muncalè, 4 fette di scamorza affumicata calabra, 4 fette di Porchetta, Ketchup e Maionese (entrambi Heinz)",
        prezzo: 8.5,
      },
      {
        name: "Lo Sgarro (1/2 KG)",
        image: require("@/assets/images/menu/panini/Sgarro.jpg"),
        ingredienti:
          "Doppia salsiccia d’Muncalè (240g), 8 fette di scamorza affumicata (130g), 4 fette di porchetta(130g), Ketchup, Maio e piccante",
        prezzo: 12.7,
      },
    ];
    /* eslint-disable global-require */

    return {
      swiperOptions,
      relatedProducts,
    };
  },
};
</script>

<style scoped>
.center {
  padding-bottom: 25px;
}

.img-container {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 250px;
  height: auto;
}
@media (max-width: 991px) {
  .center {
    padding-bottom: 100px;
  }
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/swiper.scss";
@import "~swiper/css/swiper.css";
</style>
