<template>
  <div class="container">
    <b-row>
      <b-col
        sm="12"
        lg="6"
        class="d-flex flex-column justify-content-center reveal"
      >
        <p class="card-title">Vieni a trovarci al nostro nuovo locale!</p>
        <p class="card-text">
          Ci trovi in
          <b style="color: var(--giallo)">Via Giulia di Barolo 25/D</b>
        </p>
        <p class="card-text">Siamo aperti tutti i giorni tranne la domenica</p>
        <p class="card-text">dalle 12:00 alle 15:00 e dalle 19:00 alle 23:00</p>
        <p class="card-text">
          Il venerdi e il sabato rimaniamo aperti fino all'<b
            style="color: var(--giallo)"
            >01:00 di notte!</b
          >
        </p>
      </b-col>
      <b-col sm="12" lg="6">
        <div class="google-maps">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2817.8197046626533!2d7.696366315243932!3d45.06916836773642!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4788738bdbf45ecf%3A0x9c43f5185b27ae5c!2sGino%20Panino!5e0!3m2!1sit!2sit!4v1667578510084!5m2!1sit!2sit"
            width="600"
            height="450"
            style="border: 0"
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col sm="12">
        <swiper-place />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BCard, BCardText } from "bootstrap-vue";
import SwiperPlace from "@/components/SwiperPlace.vue";

export default {
  components: {
    BCard,
    BCardText,
    SwiperPlace,
  },
};
</script>

<style scoped>
.google-maps {
  position: relative;
  padding-bottom: 70%;
  height: 0;
  overflow: hidden;
  margin: 20px;
}
.google-maps > iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
}

.container {
  /* background: var(--blu); */
  text-align: center;
  color: black;
}

.card-title {
  font-size: 45px;
  margin: 30px;
  line-height: 43px;
}

.card-text {
  font-size: 20px;
  margin-bottom: 20px;
}
@media (max-width: 991px) {
  .card-title {
    font-size: 40px;
    color: black;
    line-height: 43px;
    padding: 10px;
  }
  .card-text {
    font-size: 15px;
    margin-bottom: 20px;
    color: black;
    padding-left: 10px;
    padding-right: 10px;
  }
}
</style>
