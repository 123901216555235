<template>
  <div>
    <div style="margin-top: 70px">
      <div class="d-none d-lg-block">
        <img class="img-fluid" src="@/assets/images/home/copertina.jpg" />
      </div>
      <div class="d-lg-none">
        <img class="img-fluid" src="@/assets/images/home/copertinasmall.jpg" />
      </div>
      <div id="bestID" class="best">
        <div class="prova reveal">
          <span class="best-text">I panini più </span>
          <b class="panini-text">popolari</b>
        </div>
        <swiper />
      </div>
      <br />
      <br />
      <br />
      <div style="overflow-x: hidden">
        <b-row align-v="center" style="text-align: center; overflow-x: hidden">
          <b-col sm="12" lg="6" style="padding-left: 20px; padding-right: 20px">
            <p class="best-text reveal">
              Scopri il nuovo Panino con la Nutella!
            </p>
            <p class="card-text reveal">
              Il nostro <b style="color: var(--giallo)">pane</b> al latte
              incontra<br />
              l’inconfondibile gusto della
              <b style="color: var(--giallo)">Nutella</b><br />
              Vieni a godertelo nel locale in Via Giulia di Barolo 25/D.
            </p>
          </b-col>
          <b-col sm="12" lg="6" style="margin-bottom: 40px">
            <b-img
              class="img-fluid imageHome"
              src="@/assets/images/menu/panini/panenutella.jpeg"
            ></b-img>
          </b-col>
        </b-row>
      </div>

      <div style="overflow-x: hidden">
        <b-row align-v="center" style="text-align: center; overflow-x: hidden">
          <b-col sm="12" style="margin-bottom: 20px" class="d-lg-none">
            <p class="best-text reveal">Scegli i panini che più ti piacciono</p>
            <p class="card-text reveal">
              Tra poco sarà possibile ordinare direttamente dal sito web.
              Intanto consulta il nostro menù!
            </p>
            <router-link to="/menu" class="reveal"
              ><b-button>Il nostro menù</b-button></router-link
            >
          </b-col>
          <b-col sm="12" lg="6" class="">
            <img
              class="img-fluid imageHome"
              src="@/assets/images/home/ImageHome.jpg"
            />
          </b-col>
          <b-col
            sm="12"
            lg="6"
            style="margin-top: 20px"
            class="d-none d-lg-block"
          >
            <p class="best-text reveal">Scegli i panini che più ti piacciono</p>
            <p class="card-text reveal">
              Tra poco sarà possibile ordinare direttamente dal sito web.<br />
              Intanto consulta il nostro menù!
            </p>
            <router-link to="/menu" class="reveal"
              ><b-button>Il nostro menù</b-button></router-link
            >
          </b-col>
        </b-row>
      </div>

      <div id="WhereID"></div>
      <div class="dove reveal">
        <dove-siamo />
      </div>
      <!-- <div id="AboutID"></div>
      <div class="about">
        <About />
      </div> -->
      <div class="footer">
        <div class="text">
          <b-row>
            <b-col sm="6">
              <h1 style="color: white">Contattaci</h1>
              <a href="tel:0112076737" class="text-muted">011 207 6737</a>
              <br />
              <a
                href="mailto:info@ginopanino.com"
                class="text-muted"
                style="color: white"
                >info@ginopanino.com</a
              >
            </b-col>
            <b-col sm="6">
              <h1 style="color: white">Seguici sui Social</h1>
              <a
                href="https://www.instagram.com/ginopanino_official/"
                target="_ blank"
                ><img src="@/assets/images/footer/instagram.png" class="icona"
              /></a>
              <a href="https://www.facebook.com/gino.panino.56" target="_ blank"
                ><img src="@/assets/images/footer/facebook.png" class="icona"
              /></a>
              <a
                href="https://l.facebook.com/l.php?u=https%3A%2F%2Fapi.whatsapp.com%2Fsend%3Fphone%3D393475267552%26app%3Dfacebook%26entry_point%3Dpage_cta%26fbclid%3DIwAR1lDquJMwEjy8LxMxfpNJAXnXeDCBaibLX8auhAJCbGsMnlyXWGdlWrlPg&h=AT2Sv_KA-pj5mDwzFgCYmveh3fsh_8OgjehHqI6FCdD96XCgh6WFlEWIcw026IIljje7J6e27zPWNkbutoT3YRgBQR5c8xizSXDfpjDi07SVOA0Wz45_0Vt-STmOA1BUSKb2QSX7JPWml1JQ_4oquWsc"
                target="_ blank"
                ><img src="@/assets/images/footer/whatsapp.png" class="icona"
              /></a>
            </b-col>
          </b-row>
        </div>
      </div>
    </div>
    <!-- <b-modal
      v-model="h"
      title="Il locale è aperto!"
      centered
      ok-title="Ordina online"
      @ok="ordina()"
      ok-only
    >
      <div style="text-align: center">
        <b-img
          src="@/assets/images/ordina/delivery.png"
          style="max-width: 200px"
        ></b-img>
      </div>
      <p>
        Il locale è aperto e accettiamo ordini online. Non perdere l'occasione,
        ordina subito e ricevi i tuoi panini preferiti direttamente a casa tua.
      </p>
    </b-modal> -->
  </div>
</template>

<script>
import StreetFoodNotCss from "@/components/animated-images/street-food/street-food-not-css.vue";
import DoveSiamo from "./DoveSiamo.vue";
import { Flicking } from "@egjs/vue-flicking";
import Swiper from "@/components/Swiper.vue";
import Footer from "@/components/Footer.vue";
import About from "./About.vue";
export default {
  components: {
    StreetFoodNotCss,
    DoveSiamo,
    Flicking,
    Swiper,
    Footer,
    About,
  },
  data() {
    return {
      h: false,
    };
  },
  methods: {
    ordina() {
      this.$router.push("/ordina");
    },
  },
  mounted() {
    // if (this.$store.state.counter == 0) {
    //   var h =
    //     new Date().toLocaleTimeString()[0] + new Date().toLocaleTimeString()[1];
    //   if ((h >= 12 && h < 13) || (h >= 19 && h < 21)) {
    //     this.h = true;
    //     this.$store.commit("increment");
    //   }
    // }
  },
};
</script>

<style scoped>
.best {
  width: 100%;
  height: 493px;
  background-image: url("../assets/images/home/background-best-panini.png");
  background-size: 100% 100%;
  text-align: center;
}
.prova {
  padding-top: 95px;
}
.best-text {
  font-size: 45px;
  color: black;
  line-height: 43px;
}
.panini-text {
  font-size: 45px;
  color: var(--blu);
}
.card-text {
  font-size: 20px;
  margin-bottom: 20px;
  color: black;
  line-height: 35px;
}

.imageHome {
  border-radius: 20%;
  width: 80%;
  max-width: 400px;
  height: auto;
}

.dove {
  margin-top: 50px;
}

.about {
  margin-top: 10px;
}
@media (max-width: 991px) {
  .imageHome {
    border-radius: 20%;
    width: 80%;
    max-width: 250px;
    height: auto;
  }
  .best-text {
    font-size: 40px;
    color: black;
    line-height: 43px;
    padding: 20px;
  }
  .card-text {
    font-size: 15px;
    margin-bottom: 20px;
    color: black;
    line-height: 35px;
    padding: 20px;
  }
}
</style>

<style scoped>
.footer {
  width: 100%;
  height: 270px;
  background-image: url("../assets/images/footer/background-footer-blue.png");
  background-size: 100% 100%;
  text-align: center;
  overflow: hidden;
}

.text {
  padding-top: 100px;
  color: white;
}
.icona {
  width: 30px;
  margin-right: 20px;
}
</style>