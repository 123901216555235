<template>
  <div class="container">
    <b-row>
      <b-col
        sm="12"
        lg="8"
        class="d-flex flex-column justify-content-center reveal"
      >
        <p class="card-title">Il nostro furgoncino rimane operativo!</p>
        <p class="card-text">
          Il furgoncino storico di Gino Panino in
          <b style="color: var(--giallo)">Corso Unità d'italia</b>
          a Torino rimarrà aperto come sempre tutti i venerdi e i sabati dalle
          24:00 alle 5:00!
        </p>
      </b-col>
      <b-col sm="12" lg="4">
        <b-img src="@/assets/images/home/CamionStoria.png"></b-img>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BCard, BCardText } from "bootstrap-vue";
import SwiperPlace from "@/components/SwiperPlace.vue";

export default {
  components: {
    BCard,
    BCardText,
    SwiperPlace,
  },
};
</script>

<style scoped>
.google-maps {
  position: relative;
  padding-bottom: 70%; /* Definisco l'aspect ratio */
  height: 0;
  overflow: hidden;
  margin: 20px;
}
.google-maps > iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
}

.container {
  /* background: var(--blu); */
  text-align: center;
  color: black;
}

.card-title {
  font-size: 45px;
  margin: 30px;
  line-height: 43px;
}

.card-text {
  font-size: 20px;
  margin-bottom: 20px;
  line-height: 35px;
}
</style>
